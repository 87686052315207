/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listSysStation = params => axios({
    url: '/api/base/system/station/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addSysStation = params => axios({
    url: '/api/base/system/station/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editSysStation = params => axios({
    url: '/api/base/system/station/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delSysStation = params => axios({
    url:'/api/base/system/station/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdSysStation = params => axios({
    url: '/api/base/system/station/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
